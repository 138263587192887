import Config from "./Config.json";

export const getCurrentDate = () => {
    const today = new Date();

    if((today.getMonth() + 1) < 10){
        var currentMonth = "0" + (today.getMonth() + 1)
    }else{
        currentMonth = today.getMonth();
    }

    if(today.getDate() < 10){
        var currentDay = "0" + today.getDate();
    }else{
        currentDay = today.getDate();
    }

    if(today.getHours() < 10){
        var currentHour = "0" + today.getHours();
    }else{
        currentHour = today.getHours();
    }

    if(today.getMinutes() < 10){
        var currentMinutes = "0" + today.getMinutes();
    }else{
        currentMinutes = today.getMinutes();
    }

    if(today.getSeconds() < 10){
        var currentSeconds = "0" + today.getSeconds();
    }else{
        currentSeconds = today.getSeconds();
    }

    return currentDay + '-' + currentMonth + '-' + today.getFullYear() + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
  };

export const getToken = () => {
    var details = Config.API_AUTH_CRED;
    
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Config.API_AUTH_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    })
    .then(response => response.json())
    .then(result => {
        console.log(result);
        sessionStorage.setItem('token', result.access_token);
    })
};

export const dataSD = () => {
    var SDData = [];
    fetch(Config.API_URL + "/queues/groups/" + Config.API_QUEUE.IDSD + "/state", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        SDData.push(data);
    })
    return SDData;
}

export const getSDMembers = (callback) =>{
    fetch(Config.API_URL + "/agents/groups/" + Config.API_QUEUE.SDMembers + "/members", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items
        var agentgroupid = Config.API_AGENT_GROUPS.SDMembers;
        return callback(agentgroupid, items);
    })
}

export const getAgentStates = (callback) => {
    fetch(Config.API_URL + "/agents/states", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items
        var agentgroupid = Config.API_AGENT_GROUPS.IDSD;
        return callback(agentgroupid, items);
    })
}

export const getAgentConversations = () => {
    return fetch(Config.API_URL + "/conversations", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        var items = data._embedded.items
        return items;
    })
}